<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft p-4">
            <div class="row align-items-center justify-content-between">
              <div class="col-7">
                <div class="text-white">
                  <h2 class="text-white">{{ nameApp }}</h2>
                  <h6 class="text-white">Recuperar Contraseña.</h6>
                </div>
              </div>
              <div class="col-5 align-self-end mb-3">
                <img :src="logoApp" alt class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="p-2">
              <b-alert v-model="isResetError" class="mb-4" variant="danger" dismissible>{{
                $v.email.$error.required
                  ? 'Correo electrónico es requerido.'
                  : 'Ingresa un formato de Correo Electrónico válido.'
              }}</b-alert>
              <form @submit.prevent="sendForgotPassword">
                <div class="mb-3">
                  <label for="email">Correo electrónico</label>
                  <input
                    type="email"
                    v-model="email"
                    class="form-control"
                    id="email"
                    placeholder="Ingresa el Correo electrónico"
                    :class="{ 'is-invalid': submitted && $v.email.$error }"
                  />
                </div>
                <div class="mb-3 row">
                  <div class="col-12 d-grid">
                    <b-button class="mt-2 btn-block" variant="outline-dark" type="submit">
                      <b-spinner v-if="loading" class="mx-2" small></b-spinner>
                      Enviar Enlace
                    </b-button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
          <p>
            ¿Ya tienes una cuenta?
            <router-link tag="a" to="/login" class="fw-medium text-primary"
              >Iniciar sesión</router-link
            >
          </p>
          <Footer></Footer>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from '../../layouts/auth'
import appConfig from '@/app.config'
import { required, email } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import Footer from './components/footer.vue'
import { nameApp, logoApp } from '@/constants/config'

/**
 * Forgot Password component
 */
export default {
  page: {
    title: 'Has olvidado tu contraseña',
    meta: [
      {
        name: 'description',
        content: appConfig.description
      }
    ]
  },
  components: {
    Layout,
    Footer
  },
  data() {
    return {
      nameApp,
      logoApp,
      email: '',
      submitted: false,
      error: null,
      tryingToReset: false,
      isResetError: false,
      loading: false
    }
  },
  validations: {
    email: {
      required,
      email
    }
  },
  methods: {
    ...mapActions({ forgotPassword: 'user/forgotPassword' }),
    async sendForgotPassword() {
      this.loading = true
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.isResetError = true
        this.loading = false
        return
      } else {
        await this.forgotPassword({
          email: this.email
        })
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" module></style>
